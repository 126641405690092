import * as React from "react";
import { FC } from "react";
import {
  AutocompleteInput,
  DateInput,
  Filter,
  ReferenceInput,
  SearchInput,
  SelectInput,
  FilterProps,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { Users } from "../types";

const useFilterStyles = makeStyles({
  status: { width: 150 },
});

const ReviewFilter: FC<Omit<FilterProps, "children">> = (props) => {
  const classes = useFilterStyles();
  return (
    <Filter {...props}>
      <SearchInput source="q" alwaysOn />
      <SelectInput
        source="status"
        choices={[
          { id: "open", name: "Open" },
          { id: "answered", name: "Answered" },
          // { id: "confirmed", name: "Confirmed" },
        ]}
        className={classes.status}
      />
      <ReferenceInput source="parent_id" label="Id" reference="user">
        <AutocompleteInput
          optionText={(choice?: Users) =>
            choice?.id // the empty choice is { id: '' }
              ? `${choice.id}`
              : ""
          }
        />
      </ReferenceInput>
      <DateInput source="date_since" label="Date ~" />
      <DateInput source="date_before" label="~ Date" />
    </Filter>
  );
};

export default ReviewFilter;
