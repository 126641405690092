import * as React from "react";
import { NumberField, NumberFieldProps } from "react-admin";

const ColoredNumberField = (props: NumberFieldProps) => {
  return props.record && props.source ? (
    props.record[props.source] === "refund" ? (
      <span className="bg-red-300 w-full h-full block">
        <NumberField {...props} />
      </span>
    ) : (
      <NumberField {...props} />
    )
  ) : null;
};

ColoredNumberField.defaultProps = NumberField.defaultProps;

export default ColoredNumberField;
