import ShortUniqueId from "short-unique-id";
import * as React from "react";
import {
  Create,
  CreateProps,
  DateInput,
  SimpleForm,
  TextInput,
  useTranslate,
  PasswordInput,
  required,
  email,
} from "react-admin";
import { AnyObject } from "react-final-form";
import { Typography, Box } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Styles } from "@material-ui/styles/withStyles";

export const styles: Styles<Theme, any> = {
  first_name: { display: "inline-block" },
  last_name: { display: "inline-block", marginLeft: 32 },
  email: { width: 544 },
  address: { maxWidth: 544 },
  zipcode: { display: "inline-block" },
  city: { display: "inline-block", marginLeft: 32 },
  comment: {
    maxWidth: "20em",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  password: { display: "inline-block" },
  confirm_password: { display: "inline-block", marginLeft: 32 },
};

const useStyles = makeStyles(styles);

export const validatePasswords = ({
  password,
  confirm_password,
}: AnyObject) => {
  const errors = {} as any;

  if (password && confirm_password && password !== confirm_password) {
    errors.confirm_password = ["resources.youtube.errors.password_mismatch"];
  }

  return errors;
};

const ChannelCreate = (props: CreateProps) => {
  const classes = useStyles(props);
  const date = new Date();
  const makeUID = new ShortUniqueId({ length: 10 });
  const shortUID = makeUID();

  return (
    <Create {...props}>
      <SimpleForm
        // Here for the GQL provider
        initialValues={{
          createdAt: date,
          uid: shortUID,
        }}
        validate={validatePasswords}
      >
        <SectionTitle label="resources.youtube.fieldGroups.identity" />
        <TextInput
          autoFocus
          source="name"
          formClassName={classes.first_name}
          validate={requiredValidate}
        />
        <TextInput
          source="url"
          formClassName={classes.last_name}
          validate={requiredValidate}
        />
        <TextInput
          source="uid"
          fullWidth
          formClassName={classes.email}
          disabled
        />
        <TextInput
          source="utm"
          fullWidth
          // formClassName={classes.email}
          validate={requiredValidate}
        />
        <DateInput source="createdAt" disabled />
      </SimpleForm>
    </Create>
  );
};

const requiredValidate = [required()];

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label)}
    </Typography>
  );
};

const Separator = () => <Box pt="1em" />;

export default ChannelCreate;
