import React, { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import styled, { keyframes, css } from "styled-components";

import { ReactComponent as CloseButton } from "assets/close.svg";

const slide = keyframes`
0% {
 opacity: 0;
 transform: translateY(-10px);
}
50% {
 opacity: 0.5;
}
100% {
 opacity: 1;
 transform: translateY(0px);
}
`;

const ModalWrapper: any = styled.div<IProps>`
  box-sizing: border-box;
  display: ${(props) => (props.visible ? "block" : "none")};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;
  outline: 0;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalOverlay = styled.div<IProps>`
  box-sizing: border-box;
  display: ${(props) => (props.visible ? "block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
`;

const ModalInner = styled.div`
  box-sizing: border-box;
  position: relative;
  /* box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5); */
  /* width: 954px; */
  outline: none;

  margin: 0 auto;
  opacity: 0;

  ${() => css`
    display: flex;
    animation: ${slide} 500ms 100ms ease-in-out forwards;
  `}
`;

interface IProps {
  visible?: boolean;
}

function BasicModal({
  title,
  className,
  onClose,
  maskClosable,
  closable,
  visible,
  children,
  neverMind,
  makePW,
  stroke,
}: any) {
  const [isBrowser, setIsBrowser] = useState(false);
  const [isOutSide, setIsOutSide] = useState(false);

  const scrollPosition: any = useRef();

  const isOverlay = (e: any) => {
    if (
      e.target.tagName.toLowerCase() === "div" &&
      e.target.className.toLowerCase().includes("modal")
    ) {
      setIsOutSide(true);
    } else {
      setIsOutSide(false);
    }
  };

  const onMaskClick = (e: any) => {
    if (e.target === e.currentTarget) {
      onClose(e);
    }
  };

  const close = (e: any) => {
    if (onClose && e.key === "Escape") {
      e.preventDefault();
      onClose(e);
    }
  };

  useEffect(() => {
    setIsBrowser(true);
  }, []);

  useEffect(() => {
    if (visible) {
      document.body.style.cssText = `
      position: fixed;
      top: -${scrollPosition.current}px;
      overflow-y: scroll;
      width: 100%;`;
    }
    return () => {
      document.body.style.cssText = "";
      window.scrollTo(0, scrollPosition.current);
    };
  }, [visible]);

  useEffect(() => {
    const Scroll = () => {
      if (!visible) {
        scrollPosition.current = window.scrollY;
      }
    };

    window.addEventListener("scroll", Scroll);
    window.addEventListener("keydown", close);
    return () => {
      window.removeEventListener("scroll", Scroll);
      window.removeEventListener("keydown", close);
    };
  });

  const modalContent = visible ? (
    <>
      <ModalOverlay visible={visible} />
      <ModalWrapper
        className={className}
        onMouseDown={isOverlay}
        onClick={onMaskClick}
        tabIndex={-1}
        visible={visible}
      >
        <ModalInner
          tabIndex={0}
          className="relative modal-inner max-w-5xl flex justify-center items-center"
        >
          {children}

          <div className="absolute flex flex-row w-full top-6">
            {title && (
              <div className="font-inter text-lg text-white text-center flex w-full h-12 pt-1 justify-center items-center">
                {title}
              </div>
            )}

            {closable && (
              <button
                type="button"
                className="modal-close absolute right-6 outline-none cursor-pointer"
                onClick={onClose}
              >
                <CloseButton width={48} height={48} stroke={stroke} />
              </button>
            )}
          </div>
        </ModalInner>
      </ModalWrapper>
    </>
  ) : null;

  if (isBrowser) {
    const divElement: any = document.getElementById("modal-root");
    return createPortal(modalContent, divElement);
  }

  return null;
}

BasicModal.defaultProps = {
  visible: false,
  closable: true,
  maskClosable: true,
  keydown: true,
};

export default BasicModal;
