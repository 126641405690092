import * as React from "react";
import { FC } from "react";
import {
  Identifier,
  Datagrid,
  DateField,
  TextField,
  DatagridProps,
  EmailField,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

import rowStyle from "./rowStyle";
import UserReferenceField from "../users/UserReferenceField";
import ColoredNumberField from "../users/ColoredNumberField";

const useListStyles = makeStyles({
  headerRow: {
    borderLeftColor: "transparent",
    borderLeftWidth: 5,
    borderLeftStyle: "solid",
  },
  headerCell: {
    padding: "6px 8px 6px 8px",
  },
  rowCell: {
    padding: "6px 8px 6px 8px",
  },
  comment: {
    maxWidth: "18em",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
});

export interface InquiryListDesktopProps extends DatagridProps {
  selectedRow?: Identifier;
}

const InquiryListDesktop: FC<InquiryListDesktopProps> = ({
  selectedRow,
  ...props
}) => {
  const classes = useListStyles();

  return (
    <Datagrid
      rowClick="edit"
      // @ts-ignore
      rowStyle={rowStyle(selectedRow)}
      classes={{
        headerRow: classes.headerRow,
        headerCell: classes.headerCell,
        rowCell: classes.rowCell,
      }}
      optimized
      {...props}
    >
      <DateField source="date" />
      <UserReferenceField link={false} />
      <EmailField source="email" />
      <TextField source="parent_id" label="Id" />
      <ColoredNumberField source="subject" />
      <TextField source="content" cellClassName={classes.comment} />
      <TextField source="status" />
    </Datagrid>
  );
};

export default InquiryListDesktop;
