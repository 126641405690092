import YoutubeIcon from "@material-ui/icons/YouTube";
import ChannelList from "./ChannelList";
import ChannelCreate from "./ChannelCreate";
import ChannelEdit from "./ChannelEdit";

const resource = {
  list: ChannelList,
  create: ChannelCreate,
  edit: ChannelEdit,
  icon: YoutubeIcon,
};

export default resource;
