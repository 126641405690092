import PeopleIcon from "@material-ui/icons/People";

import UsersList from "./UsersList";
// import UsersCreate from "./UsersCreate";
// import UsersEdit from "./UsersEdit";

const resource = {
  list: UsersList,
  //   create: UsersCreate,
  //   edit: UsersEdit,
  icon: PeopleIcon,
};

export default resource;
