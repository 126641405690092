import React, { FC, useState, useEffect, useRef } from "react";
import { Users } from "../types";
import {
  FieldProps,
  useUpdate,
  useNotify,
  useRedirect,
  useRefresh,
  BooleanInput,
  DateInput,
} from "react-admin";

import styled from "styled-components";
import { apiUrl } from "dataProvider";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Switch,
} from "@material-ui/core";
import postData from "utils/postData";

const InputBox = styled.input`
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  height: 100%;
  letter-spacing: 0.02rem;
  color: black;
  width: 100%;

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #ffffff inset;
    -webkit-text-fill-color: #000000;
  }

  &::placeholder {
    color: #b1afb8;
  }
`;

const TextArea = styled.textarea`
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.02rem;
  color: black;
  width: 100%;
  height: 16.8rem;
  border: none;
  margin: 16px 0;
  background: transparent;

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #ffffff inset;
  }
  &:focus {
    outline: none !important;
  }

  &::placeholder {
    color: #b1afb8;
  }
`;

const ContentCount = styled.div`
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 100%;
  text-align: right;
  color: #504e55;
  margin-bottom: 8px;
`;

interface Props extends FieldProps<Users> {
  className?: string;
  size?: string;
}

function isJSON(str: string) {
  try {
    JSON.parse(str);
    return true;
  } catch (error) {
    return false;
  }
}

const UpdateField: FC<Props> = ({ record }: any) => {
  const notify = useNotify();
  const refresh = useRefresh();

  const { nextBillingAt } = record;
  const isJSONFormat = isJSON(record.subscriptionDetail);
  const originData = isJSONFormat && JSON.parse(record.subscriptionDetail);
  const voucherData: any =
    Array.isArray(originData) &&
    Object.values(originData[originData.length - 1])[0];
  const { name, amount, currency } =
    voucherData === false && originData
      ? {
          name: originData.name,
          amount: originData.amount,
          currency: originData.currency,
        }
      : { name: voucherData.type, amount: 0, currency: null };
  const [dateValue, setDateValue] = useState<Date | any>();

  const handleTimeZone = (date: any) => {
    const originalTime = new Date(date);
    const utcTime = new Date(originalTime.getTime() + 9 * 60 * 60000);
    const formattedDate = utcTime.toISOString().split("T");
    console.log("utcTime:", utcTime);
    console.log("formattedDate:", formattedDate);

    return { formattedDate: formattedDate[0], timeData: formattedDate[1] };
  };

  useEffect(() => {
    const { formattedDate } = handleTimeZone(nextBillingAt);

    setDateValue(formattedDate);
  }, []);

  const updatePaymentSchedule = async () => {
    try {
      const { formattedDate, timeData } = handleTimeZone(nextBillingAt);
      console.log("update paymentSchedule", dateValue);
      const res = await postData(
        process.env.NODE_ENV === "development"
          ? "http://localhost:9009/update-payment-schedule"
          : "https://musia.ai/one/api/update-payment-schedule",
        {
          userId: record.id,
          originBillingAt: formattedDate.concat("T", timeData),
          nextBillingAt: dateValue.concat("T", timeData),
          subscriptionDetail: record.subscriptionDetail,
        }
      );

      if (res) {
        notify(
          "resources.user.notification.approved_success",
          "info",
          {},
          true
        );
        refresh();
      }
    } catch (err) {
      console.log(err);
      notify("resources.user.notification.approved_error", "warning");
    }
  };

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const modifiedDate: any = event.target.valueAsDate;
    const { formattedDate } = handleTimeZone(modifiedDate);

    setDateValue(formattedDate);
  };

  const blockBtn = () => {
    if (nextBillingAt) {
      return true;
    }

    return false;
  };

  return (
    <div className="sendInquire w-full pt-8">
      <div className="font-inter text-base font-bold mb-2">
        요약 정보
        <div className="font-normal text-black text-xs">
          구매 플랜: {name}
          <br />
          다음 결제일:{" "}
          {nextBillingAt ? new Date(nextBillingAt).toLocaleString() : ""}
          <br />
          결제 요금: {amount}{" "}
          {currency ? (currency === "KRW" ? "원" : "USD") : ""}
        </div>
      </div>
      <div className="flex flex-col mt-8">
        <div className="font-inter font-bold text-black text-lg">
          다음 결제일 변경
        </div>

        <div className={`mt-4 table-fixed font-inter text-black`}>
          <input
            type="date"
            value={nextBillingAt ? dateValue : ""}
            onChange={handleDateChange}
          />

          <div className="p-4 flex">
            <button
              className="outline-none"
              type="button"
              onClick={updatePaymentSchedule}
              disabled={!blockBtn()}
            >
              <div
                className={`
                      text-lg font-mono py-3 px-6
                      ${blockBtn() ? "bg-purple-200" : "bg-purple-100"}
                      ${
                        nextBillingAt
                          ? "hover:bg-purple-100 cursor-pointer"
                          : ""
                      }`}
              >
                <div className="font-mono text-lg text-white">UPDATE</div>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateField;
