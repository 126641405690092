import { Fragment, useCallback, ReactElement } from "react";
import {
  BooleanField,
  Datagrid,
  DateField,
  DateInput,
  Filter,
  FilterProps,
  List,
  ListProps,
  NullableBooleanInput,
  NumberField,
  SearchInput,
  TextField,
  EmailField,
} from "react-admin";
import { Route, RouteChildrenProps, useHistory } from "react-router-dom";
import { Drawer, useMediaQuery, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// import SegmentsField from "./SegmentsField";
// import SegmentInput from "./SegmentInput";
import UserNameLinkField from "./UserNameLinkField";
import ColoredNumberField from "./ColoredNumberField";
import MobileGrid from "./MobileGrid";
import UserListAside from "./UserListAside";
import UserEdit from "./UserEdit";

const UserFilter = (props: Omit<FilterProps, "children">) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
    <DateInput source="visitAt" />
    <NullableBooleanInput source="has_newsletter" defaultValue />
    {/* <SegmentInput /> */}
    <NullableBooleanInput source="expired" />
  </Filter>
);

const useStyles = makeStyles((theme) => ({
  // nb_commands: { color: "purple" },
  // hiddenOnSmallScreens: {
  //   display: "table-cell",
  //   [theme.breakpoints.down("md")]: {
  //     display: "none",
  //   },
  // },

  headerCell: {
    fontWeight: "bold",
  },
  drawerPaper: {
    zIndex: 100,
  },
}));

const UserList = (props: ListProps): ReactElement => {
  const classes = useStyles();
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));

  const history = useHistory();
  const handleClose = useCallback(() => {
    history.push("/user");
  }, [history]);

  return (
    <Route path="/user/:id">
      {({ match }: RouteChildrenProps<{ id: string }>) => {
        const isMatch = !!(
          match &&
          match.params &&
          match.params.id !== "create"
        );

        console.log("match", match);
        return (
          <Fragment>
            <List
              {...props}
              filters={isSmall ? <UserFilter /> : undefined}
              sort={{ field: "visitAt", order: "ASC" }}
              perPage={25}
              aside={<UserListAside />}
            >
              {isXsmall ? (
                <MobileGrid />
              ) : (
                <Datagrid classes={classes} optimized rowClick="edit">
                  <TextField source="id" />
                  <UserNameLinkField />
                  {/* <TextField source="uid" /> */}
                  <EmailField source="email" />
                  {/* <TextField source="providerId" /> */}
                  {/* <TextField source="subscription" /> */}
                  {/* <TextField source="uuid" /> */}
                  {/* <TextField source="validity" /> */}
                  {/* <TextField source="signUpDate" /> */}
                  <DateField source="createdAt" />
                  <TextField source="visitAt" />
                  <TextField source="country" />
                  {/* <TextField source="platform" /> */}
                  <ColoredNumberField source="inquiries" />
                  <BooleanField source="has_newsletter" label="News." />
                  <BooleanField source="expired" />
                </Datagrid>
              )}
            </List>
            <Drawer
              variant="persistent"
              open={isMatch}
              anchor="right"
              onClose={handleClose}
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              {isMatch ? (
                <UserEdit
                  id={(match as any).params.id}
                  onCancel={handleClose}
                  resource={props.resource}
                  basePath={props.basePath}
                />
              ) : null}
            </Drawer>
          </Fragment>
        );
      }}
    </Route>
  );
};

export default UserList;
