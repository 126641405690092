import * as React from "react";
import {
  DateInput,
  Edit,
  EditProps,
  NullableBooleanInput,
  TextInput,
  PasswordInput,
  Toolbar,
  useTranslate,
  FormWithRedirect,
  required,
  email,
  FieldProps,
} from "react-admin";
import { Box, Card, CardContent, Typography } from "@material-ui/core";

import FullNameField from "./FullNameField";
import { validatePasswords } from "./ChannelCreate";
import { Users } from "../types";

const ChannelEdit = (props: EditProps) => {
  return (
    <Edit title={<VisitorTitle />} component="div" {...props}>
      <VisitorForm />
    </Edit>
  );
};

const VisitorTitle = ({ record }: FieldProps<Users>) =>
  record ? <FullNameField record={record} size="32" /> : null;

const VisitorForm = (props: any) => {
  const translate = useTranslate();

  return (
    <FormWithRedirect
      {...props}
      validate={validatePasswords}
      render={(formProps: any) => (
        <Card>
          <form>
            <CardContent>
              <Box display={{ md: "block", lg: "flex" }}>
                <Box flex={2} mr={{ md: 0, lg: "1em" }}>
                  <Typography variant="h6" gutterBottom>
                    {translate("resources.youtube.fieldGroups.identity")}
                  </Typography>
                  <Box display={{ xs: "block", sm: "flex" }}>
                    <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                      <TextInput
                        source="name"
                        resource="youtube"
                        validate={requiredValidate}
                        fullWidth
                      />
                    </Box>
                    <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                      <TextInput
                        source="url"
                        resource="youtube"
                        validate={requiredValidate}
                        fullWidth
                      />
                    </Box>
                  </Box>
                  <TextInput
                    fullWidth
                    source="uid"
                    resource="youtube"
                    disabled
                  />
                  <TextInput fullWidth source="utm" resource="youtube" />
                  <Box display={{ xs: "block", sm: "flex" }}>
                    <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                      <DateInput
                        source="createdAt"
                        resource="youtube"
                        fullWidth
                        helperText={false}
                      />
                    </Box>
                    <Box flex={2} ml={{ xs: 0, sm: "0.5em" }} />
                  </Box>
                </Box>
              </Box>
            </CardContent>
            <Toolbar
              record={formProps.record}
              basePath={formProps.basePath}
              undoable={true}
              invalid={formProps.invalid}
              handleSubmit={formProps.handleSubmit}
              saving={formProps.saving}
              resource="youtube"
            />
          </form>
        </Card>
      )}
    />
  );
};

const requiredValidate = [required()];

export default ChannelEdit;
