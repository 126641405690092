import { ReactElement } from "react";
import {
  BooleanField,
  Datagrid,
  DateField,
  DateInput,
  Filter,
  FilterProps,
  List,
  ListProps,
  NullableBooleanInput,
  NumberField,
  SearchInput,
  TextField,
  EmailField,
  UrlField,
} from "react-admin";
import { useMediaQuery, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// import SegmentsField from "./SegmentsField";
// import SegmentInput from "./SegmentInput";
import UserNameLinkField from "./UserNameLinkField";
import ColoredNumberField from "./ColoredNumberField";
import Aside from "./Aside";

const UserFilter = (props: Omit<FilterProps, "children">) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
    <DateInput source="visitAt" />
    <NullableBooleanInput source="has_newsletter" defaultValue />
    {/* <SegmentInput /> */}
    <NullableBooleanInput source="expired" />
  </Filter>
);

const useStyles = makeStyles((theme) => ({
  // nb_commands: { color: "purple" },
  // hiddenOnSmallScreens: {
  //   display: "table-cell",
  //   [theme.breakpoints.down("md")]: {
  //     display: "none",
  //   },
  // },

  headerCell: {
    fontWeight: "bold",
  },
}));

const ChannelList = (props: ListProps): ReactElement => {
  const classes = useStyles();
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  return (
    <List
      {...props}
      // filters={isSmall ? <UserFilter /> : undefined}
      // sort={{ field: "visitAt", order: "ASC" }}
      // perPage={25}
      // aside={<Aside />}
    >
      <Datagrid classes={classes} optimized rowClick="edit">
        <NumberField source="id" />
        <TextField source="uid" />
        <TextField source="name" />
        {/* <UserNameLinkField /> */}
        <UrlField source="url" target="_blank" rel="noopener noreferrer" />
        <TextField source="utm" />
        <DateField source="createdAt" />
      </Datagrid>
    </List>
  );
};

export default ChannelList;
