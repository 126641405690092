import * as React from "react";
import { FC } from "react";
import PropTypes from "prop-types";
import NewReleasesIcon from "@material-ui/icons/NewReleases";

import {
  Button,
  useUpdateMany,
  useNotify,
  useRefresh,
  useUnselectAll,
  CRUD_UPDATE_MANY,
  BulkActionProps,
  Identifier,
} from "react-admin";

const noSelection: Identifier[] = [];

const BulkRejectButton: FC<BulkActionProps> = ({
  selectedIds = noSelection,
}) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const unselectAll = useUnselectAll("asks");

  const [reject, { loading }] = useUpdateMany(
    "asks",
    selectedIds,
    { status: "open" },
    {
      action: CRUD_UPDATE_MANY,
      undoable: true,
      onSuccess: () => {
        notify(
          "resources.asks.notification.approved_success",
          "info",
          {},
          true
        );
        refresh();
        unselectAll();
      },
      onFailure: () => {
        notify("resources.asks.notification.approved_error", "warning");
      },
    }
  );

  return (
    <Button
      label="resources.asks.action.open"
      onClick={reject}
      disabled={loading}
    >
      <NewReleasesIcon />
    </Button>
  );
};

BulkRejectButton.propTypes = {
  selectedIds: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default BulkRejectButton;
