import * as React from "react";
import { FC } from "react";
import { Link, FieldProps } from "react-admin";

import FullNameField from "./FullNameField";
import { Users } from "../types";

const CustomerLinkField: FC<FieldProps<Users>> = (props) =>
  props.record ? (
    <Link to={`/`}>
      {/* // <Link to={`/customers/${props.record.id}`}> */}
      <FullNameField {...props} />
    </Link>
  ) : null;

CustomerLinkField.defaultProps = {
  source: "name",
  addLabel: true,
};

export default CustomerLinkField;
