import * as React from "react";
import { FC } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { useTranslate, useUpdate, useNotify, useRedirect } from "react-admin";
import { Inquiry } from "../types";

/**
 * This custom button demonstrate using useUpdate to update data
 */
const AcceptButton: FC<{ record: Inquiry }> = ({ record }) => {
  const translate = useTranslate();
  const notify = useNotify();
  const redirectTo = useRedirect();

  const [approve, { loading }] = useUpdate(
    "asks",
    record.id,
    { status: "answered" },
    record,
    {
      undoable: true,
      onSuccess: () => {
        notify(
          "resources.asks.notification.approved_success",
          "info",
          {},
          true
        );
        redirectTo("/asks");
      },
      onFailure: () => {
        notify("resources.asks.notification.approved_error", "warning");
      },
    }
  );
  return (record && record.status === "open") ||
    record.status === "answered" ? (
    <Button
      variant="outlined"
      color="primary"
      size="small"
      onClick={approve}
      disabled={loading}
    >
      <CheckCircleIcon
        color="primary"
        style={{ paddingRight: "0.1em", color: "green" }}
      />
      {translate("resources.asks.action.answered")}
    </Button>
  ) : (
    <span />
  );
};

AcceptButton.propTypes = {
  record: PropTypes.any,
};

export default AcceptButton;
