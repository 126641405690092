import * as React from "react";
import { SVGProps } from "react";
import { useTheme } from "@material-ui/core/styles";

import { ReactComponent as LogoIcon } from "assets/musia-logo.svg";

const Logo = (props: SVGProps<SVGSVGElement>) => {
  const theme = useTheme();

  return <LogoIcon fill={theme.palette.secondary.light} />;
};

export default Logo;
