import React, { useContext, useEffect, useRef, useState } from "react";
import { Admin, Resource } from "react-admin";
import polyglotI18nProvider from "ra-i18n-polyglot";

import authProvider from "./authProvider";
import themeReducer from "./themeReducer";
import { Login, Layout } from "./layout";
// import { Dashboard } from "./dashboard";
import customRoutes from "./routes";
import englishMessages from "./i18n/en";

import users from "./users";
import inquiry from "./inquiry";
import asks from "./asks";
import email from "./email";
import youtube from "./youtube";
import voucher from "./voucher";

import dataProvider from "dataProvider";

const i18nProvider = polyglotI18nProvider((locale) => {
  // if (locale === 'fr') {
  //     return import('./i18n/fr').then(messages => messages.default);
  // }

  // Always fallback on english
  return englishMessages;
}, "en");

const App = () => {
  return (
    <Admin
      title=""
      dataProvider={dataProvider}
      customReducers={{ theme: themeReducer }}
      customRoutes={customRoutes}
      authProvider={authProvider}
      // dashboard={Dashboard}
      loginPage={Login}
      layout={Layout}
      i18nProvider={i18nProvider}
      disableTelemetry
    >
      <Resource name="inquiry" {...inquiry} />
      <Resource name="asks" {...asks} />
      <Resource name="user" {...users} />
      <Resource name="email" {...email} />
      <Resource name="youtube" {...youtube} />
      <Resource name="voucher" {...voucher} />
    </Admin>
  );
};
export default App;
