import * as React from "react";
import { FC } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import NewReleasesIcon from "@material-ui/icons/NewReleases";
import { useTranslate, useUpdate, useNotify, useRedirect } from "react-admin";
import { Inquiry } from "../types";

/**
 * This custom button demonstrate using a custom action to update data
 */
const RejectButton: FC<{ record: Inquiry }> = ({ record }) => {
  const translate = useTranslate();
  const notify = useNotify();
  const redirectTo = useRedirect();

  const [reject, { loading }] = useUpdate(
    "inquiry",
    record.id,
    { status: "open" },
    record,
    {
      undoable: true,
      onSuccess: () => {
        notify(
          "resources.inquiry.notification.rejected_success",
          "info",
          {},
          true
        );
        redirectTo("/inquiry");
      },
      onFailure: () => {
        notify("resources.inquiry.notification.rejected_error", "warning");
      },
    }
  );

  return record && record.status === "answered" ? (
    <Button
      variant="outlined"
      color="primary"
      size="small"
      onClick={reject}
      disabled={loading}
    >
      <NewReleasesIcon
        color="primary"
        style={{ paddingRight: "0.1em", color: "red" }}
      />
      {translate("resources.inquiry.action.open")}
    </Button>
  ) : (
    <span />
  );
};

RejectButton.propTypes = {
  record: PropTypes.any,
};

export default RejectButton;
