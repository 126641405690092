import * as React from "react";
import {
  DateInput,
  Edit,
  EditProps,
  NullableBooleanInput,
  TextInput,
  PasswordInput,
  Toolbar,
  useTranslate,
  FormWithRedirect,
  required,
  email,
  FieldProps,
  NumberInput,
  BooleanInput,
} from "react-admin";
import { Box, Card, CardContent, Typography } from "@material-ui/core";

import FullNameField from "./FullNameField";
import { validatePasswords } from "./VoucherCreate";
import { Users } from "../types";

const VoucherEdit = (props: EditProps) => {
  return (
    <Edit title={<VisitorTitle />} component="div" {...props}>
      <VisitorForm />
    </Edit>
  );
};

const VisitorTitle = ({ record }: FieldProps<Users>) =>
  record ? <FullNameField record={record} size="32" /> : null;

const VisitorForm = (props: any) => {
  const translate = useTranslate();

  return (
    <FormWithRedirect
      {...props}
      validate={validatePasswords}
      render={(formProps: any) => (
        <Card>
          <form>
            <CardContent>
              <Box display={{ md: "block", lg: "flex" }}>
                <Box flex={2} mr={{ md: 0, lg: "1em" }}>
                  <Typography variant="h6" gutterBottom>
                    {translate("resources.voucher.fieldGroups.identity")}
                  </Typography>
                  <Box display={{ xs: "block", sm: "flex" }}>
                    <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                      <TextInput
                        source="userId"
                        resource="voucher"
                        validate={requiredValidate}
                        fullWidth
                      />
                    </Box>
                    <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                      <TextInput
                        source="email"
                        resource="voucher"
                        validate={requiredValidate}
                        fullWidth
                      />
                    </Box>
                  </Box>
                  <Box display={{ xs: "block", sm: "flex" }}>
                    <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                      <TextInput
                        source="type"
                        resource="voucher"
                        validate={requiredValidate}
                        fullWidth
                      />
                    </Box>
                    <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                      <NumberInput
                        source="value"
                        resource="voucher"
                        validate={requiredValidate}
                        fullWidth
                      />
                    </Box>
                  </Box>
                  <TextInput
                    fullWidth
                    source="code"
                    resource="voucher"
                    validate={requiredValidate}
                  />
                  <TextInput fullWidth source="source" resource="voucher" />
                  <Box display={{ xs: "block", sm: "flex" }}>
                    <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                      <DateInput
                        source="usedAt"
                        resource="voucher"
                        fullWidth
                        helperText={false}
                      />
                    </Box>
                    <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                      <DateInput
                        source="createdAt"
                        resource="voucher"
                        fullWidth
                        helperText={false}
                      />
                    </Box>
                  </Box>
                  <Box display={{ xs: "block", sm: "flex" }}>
                    {!props.record.expried && (
                      <>
                        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                          <BooleanInput
                            source="redeem"
                            resource="voucher"
                            validate={requiredValidate}
                            fullWidth
                          />
                        </Box>
                        <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                          <BooleanInput
                            source="used"
                            resource="voucher"
                            validate={requiredValidate}
                            fullWidth
                          />
                        </Box>
                      </>
                    )}

                    <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                      <BooleanInput
                        source="expired"
                        resource="voucher"
                        validate={requiredValidate}
                        fullWidth
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </CardContent>
            <Toolbar
              record={formProps.record}
              basePath={formProps.basePath}
              undoable={true}
              invalid={formProps.invalid}
              handleSubmit={formProps.handleSubmit}
              saving={formProps.saving}
              resource="voucher"
            />
          </form>
        </Card>
      )}
    />
  );
};

const requiredValidate = [required()];

export default VoucherEdit;
