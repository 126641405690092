import React, { FC, useState, useEffect, useRef } from "react";
import { Users } from "../types";
import {
  FieldProps,
  useUpdate,
  useNotify,
  useRedirect,
  useRefresh,
  BooleanInput,
} from "react-admin";

import styled from "styled-components";
import { apiUrl } from "dataProvider";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Switch,
} from "@material-ui/core";
import BasicModal from "./BasicModal";

const InputBox = styled.input`
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  height: 100%;
  letter-spacing: 0.02rem;
  color: black;
  width: 100%;

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #ffffff inset;
    -webkit-text-fill-color: #000000;
  }

  &::placeholder {
    color: #b1afb8;
  }
`;

const TextArea = styled.textarea`
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.02rem;
  color: black;
  width: 100%;
  height: 16.8rem;
  border: none;
  margin: 16px 0;
  background: transparent;

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #ffffff inset;
  }
  &:focus {
    outline: none !important;
  }

  &::placeholder {
    color: #b1afb8;
  }
`;

const ContentCount = styled.div`
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 100%;
  text-align: right;
  color: #504e55;
  margin-bottom: 8px;
`;

interface Props extends FieldProps<Users> {
  className?: string;
  size?: string;
}

const EmailForm: FC<Props> = ({}: any) => {
  const notify = useNotify();
  const refresh = useRefresh();

  const [subject, setSubject] = useState("");
  const [content, setContent] = useState("");
  const [selectedFile, setSelectedFile]: any = useState(null);
  const [checked, setChecked] = useState(true);
  const [checked2, setChecked2] = useState(true);
  const [modal, onChangeModal] = useState(false);
  const [emailType, setEmailType] = useState("all");
  const [email, setEmail] = useState("");
  const [sendList, setList]: any = useState([]);
  const [currentEmail, setCurrentEmail] = useState(1);
  const [addList, setAddList]: any = useState([]);
  const [isIndividual, setIsindividual] = useState("group");
  const [language, setLanguage] = useState("en");
  const [answerType, setAnswerType] = useState("etc");
  const [version, setVersion] = useState("");
  const [autoAnswer, setAutoAnswer]: any = useState({
    information: {
      en: `Please let us know the following:
    - Version of MUSIA Plugin
    - OS (Ex. Windows 10)
    - DAW (Ex. Ableton Live 11)\n
    Also, if you can provide detailed situation, it will be more helpful to solve your problem.\n
    If you contact us again with the above information, we will help you as soon as possible!
    `,
      ko: `다음의 사항들을 알려주세요:
    - MUSIA Plugin의 버전
    - OS (Ex. Windows 10)
    - DAW (Ex. Ableton Live 11)\n
    또한 자세한 상황을 알려주시면, 문제 해결에 도움이 됩니다.\n
    위의 정보들로 다시 한번 문의 주시면 최대한 빠르게 도움을 드리도록 하겠습니다.`,
    },
    type2: "",
    type3: "",
    type4: "",
    type5: "",
  });

  const chunk = (data = [], size = 1) => {
    const items = [...data];
    const arr = [];

    while (items.length) {
      arr.push(items.splice(0, size));
    }

    return arr;
  };

  const getEmailList = async () => {
    const res: any = await fetch(`${apiUrl}/getEmailList`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email, emailType }),
    });

    const list = await res.json();

    if (emailType === "all") {
      const spliceList = chunk(list, 1000);
      console.log("email list: ", spliceList);

      setList(spliceList);
    } else {
      setList(list);
    }
  };

  const getVersion = async () => {
    const res: any = await fetch(`${apiUrl}/getVSTversion`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    });

    const version = await res.json();
    console.log("vst version: ", version);
    setVersion(version);
  };

  useEffect(() => {
    getEmailList();
    getVersion();
  }, [email, emailType]);

  useEffect(() => {
    if (!checked2) {
      setList([]);
    }
  }, [checked2]);

  const handleFileChange = (event: any) => {
    setSelectedFile(event.target.files);
  };

  const sendGroupEmail = async (emailList: object) => {
    const formData = new FormData();

    if (selectedFile) {
      for (let i = 0; i < selectedFile.length; i++) {
        formData.append(`sendFile-${i}`, selectedFile[i], selectedFile[i].name);
      }
    }
    formData.append("subject", JSON.stringify(subject));
    formData.append("content", JSON.stringify(content));
    if (emailType === "input") {
      formData.append("targetUser", JSON.stringify(addList));
    } else {
      formData.append("targetUser", JSON.stringify(emailList));
    }

    formData.append("answerType", JSON.stringify(answerType));
    formData.append("version", JSON.stringify(version));

    const res: any = await fetch(`${apiUrl}/groupMail`, {
      method: "POST",
      // headers: { "Content-Type": "multipart/form-data" },
      body: formData,
    });

    notify("resources.mail.notification.approved_success", "info", {}, true);
    if (emailType !== "all") {
      onChangeModal(false);
    }
    console.log("file attch: ", res);

    return res;
  };

  const awaitSendMail = async (length: number) => {
    console.log("1111재귀호출: ", length);
    if (length <= 0) {
      onChangeModal(false);
      setCurrentEmail(1);
      return 1;
    }

    const res = await sendGroupEmail(sendList[length - 1]);
    if (res.status === 200) {
      console.log("22222재귀호출: ", length);
      setCurrentEmail(currentEmail + 1);
      awaitSendMail(length - 1);
    }
  };

  const onChangeContent = (e: any) => {
    const { value } = e.target;
    setContent(value);
  };

  const blockBtn = () => {
    if (answerType !== "promotion" && answerType !== "event") {
      if (content.length === 0 || subject.length === 0) {
        return true;
      }
    }

    if (sendList.length === 0) {
      return true;
    }

    return false;
  };

  const handleEmail = (event: any) => {
    setEmail(event.target.value);
  };

  const handleSubject = (event: any) => {
    setSubject(event.target.value);
  };

  const handleEmailType = (event: any, value: string) => {
    setEmailType(value);

    if (value === "input") {
      setList([]);
    }
  };

  const handleType = (event: any, value: string) => {
    setAnswerType(value);
  };

  const handleChange = (event: any, checked: boolean) => {
    setChecked(checked);
    if (checked) {
      setLanguage("en");
    } else {
      setLanguage("ko");
    }
  };

  const toggleMail = (event: any, checked: boolean) => {
    setChecked2(checked);
    if (checked) {
      setIsindividual("group");
      setEmailType("all");
    } else {
      setIsindividual("individual");
      setEmailType("input");
    }
  };

  useEffect(() => {
    switch (answerType) {
      case "information":
        setContent(autoAnswer.information[language]);
        break;
      default:
        setContent("");
        break;
    }
  }, [language, answerType]);

  useEffect(() => {
    console.log("select language: ", language);
    console.log("select auto type: ", answerType);
  }, [language, answerType]);

  const renderEmailList = () => {
    if (emailType === "input") {
      const str = addList
        .map((list: any) => {
          return Object.values(list).join();
        })
        .join(", ");

      return (
        <div className="flex flex-row">
          <div className="truncate whitespace-nowrap max-w-screen-sm">
            {str}
          </div>
          ({addList.length}명)
        </div>
      );
    }

    if (sendList.length) {
      const str = sendList
        .map((list: any) => {
          return Object.values(list).join();
        })
        .join(", ");

      return (
        <div className="flex flex-row">
          <div className="truncate whitespace-nowrap max-w-screen-sm">
            {str}
          </div>
          ({sendList.length}명)
        </div>
      );
    }

    return null;
  };

  const renderFormType = (type: string) => {
    switch (type) {
      case "promotion":
      case "event":
        return (
          <div
            className={`border border-solid border-black mt-4 table-fixed font-inter text-black`}
          >
            <div className="p-4 flex justify-end">
              <button
                className="outline-none"
                type="button"
                onClick={() => {
                  const isSend = blockBtn();

                  if (isSend) {
                    notify(
                      "resources.mail.notification.approved_error",
                      "warning"
                    );
                    return null;
                  } else {
                    onChangeModal(true);
                  }
                }}
                // disabled={blockBtn()}
              >
                <div className="text-lg font-mono py-3 px-6 bg-purple-200 hover:bg-purple-100">
                  <div className="font-mono text-lg text-white">SEND</div>
                </div>
              </button>
            </div>
            <img
              src={`https://musiaplugin.com/assets/images/${type}-sample.png`}
            />
          </div>
        );

      default:
        return (
          <div
            className={`border border-solid border-black mt-4 table-fixed font-inter text-black`}
          >
            <div>
              <div
                className={`p-4 flex h-14 border-b border-black
        `}
              >
                <div className="font-bold ">
                  {checked2 ? "전체 발송" : "개인 발송"}
                </div>
              </div>
              <div className="min-w-max px-4 flex">
                <div className="border-b border-black flex flex-row w-full h-14">
                  <div className="font-bold flex justify-start items-center w-full">
                    <div className="flex w-10">제목:</div>
                    <input
                      type="text"
                      value={subject}
                      className="outline-none flex justify-center items-center pl-2 w-full"
                      onChange={handleSubject}
                      onKeyPress={(e: any) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                          setSubject(e.target.value);
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="px-4 flex border-b border-black">
                <div className="font-inter text-sm flex flex-col w-full">
                  <TextArea
                    placeholder="내용..."
                    value={content}
                    onChange={onChangeContent}
                    className="pt-1"
                    required
                    style={{ lineHeight: "20px" }}
                  />
                  <ContentCount>{content.length}/1000</ContentCount>
                </div>
              </div>
              <div className="p-4 flex justify-between">
                <input type="file" multiple onChange={handleFileChange} />
                <button
                  className="outline-none"
                  type="button"
                  onClick={() => {
                    const isSend = blockBtn();
                    if (isSend) {
                      notify(
                        "resources.mail.notification.approved_error",
                        "warning"
                      );
                      return null;
                    } else {
                      onChangeModal(true);
                    }
                  }}
                  // disabled={blockBtn()}
                >
                  <div className="text-lg font-mono py-3 px-6 bg-purple-200 hover:bg-purple-100">
                    <div className="font-mono text-lg text-white">SEND</div>
                  </div>
                </button>
              </div>
            </div>
          </div>
        );
    }
  };

  const renderSearchList = () => {
    if (sendList.length) {
      const str = sendList
        .map((list: any) => {
          return Object.values(list).join();
        })
        .join(", ");

      return (
        <div className="flex flex-row">
          <div className="truncate whitespace-nowrap max-w-screen-sm">
            {str}
          </div>
          ({sendList.length}명)
        </div>
      );
    }

    return null;
  };

  return (
    <>
      <div className="sendInquire w-full pt-8">
        <div className="font-inter text-black opacity-50 text-xs mt-2">
          이메일 리스트 선택
        </div>
        <FormControl component="fieldset">
          <RadioGroup
            row
            defaultValue="etc"
            name="radio-buttons-group"
            value={emailType}
            onChange={handleEmailType}
          >
            <FormControlLabel
              value="all"
              control={<Radio />}
              label="모든 가입자"
            />
            <FormControlLabel
              value="subscribers"
              control={<Radio />}
              label="전체 구독자(무제한 + monthly + yearly)"
            />
            <FormControlLabel
              value="monthly"
              control={<Radio />}
              label="monthly 구독자"
            />
            <FormControlLabel
              value="yearly"
              control={<Radio />}
              label="yearly 구독자"
            />
            <FormControlLabel
              value="unlimited"
              control={<Radio />}
              label="무제한 사용자"
            />
            <FormControlLabel
              value="cancel"
              control={<Radio />}
              label="구독 취소자(구독 만료 포함)"
            />
            <FormControlLabel
              value="trial"
              control={<Radio />}
              label="trial 사용자"
            />
            <FormControlLabel
              value="trialEnd"
              control={<Radio />}
              label="trial 만료"
            />
            <div className="flex flex-row w-full">
              <FormControlLabel
                value="input"
                control={<Radio />}
                label="직접입력"
              />
              {emailType === "input" && (
                <input
                  type="email"
                  value={email}
                  className="outline-none bg-gray-100 pl-4"
                  onChange={handleEmail}
                  onKeyPress={(e: any) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      setEmail("");

                      const tmp: any = [...addList];
                      const arr = tmp.map((list: any) => {
                        return Object.values(list).join();
                      });

                      if (!checked2) {
                        setAddList([sendList[0]]);
                      } else {
                        if (arr.indexOf(sendList[0]?.email) < 0) {
                          tmp.push(sendList[0]);
                          setAddList(tmp);
                        } else {
                          tmp.slice(arr.indexOf(sendList[0]?.email), 1);
                          const filter = tmp.filter((list: any) => {
                            return list.email !== sendList[0]?.email;
                          });
                          setAddList(filter);
                        }
                      }
                    }
                  }}
                />
              )}
            </div>
          </RadioGroup>
        </FormControl>

        {emailType === "input" && (
          <div className="font-inter text-black text-xs mb-2">
            추가: 엔터 누를 시 아래 리스트에서 첫 번째 이메일 추가됨
            <br />
            삭제: 동일한 이메일 입력 후 엔터 시 삭제
            <div className="flex flex-row p-2 bg-gray-100">
              {renderSearchList()}
            </div>
          </div>
        )}

        <div className="font-inter text-black opacity-50 text-xs mb-2">
          받는 사람
        </div>
        <div className="flex flex-row p-2 bg-gray-200">{renderEmailList()}</div>

        <div className="flex flex-col mt-8">
          <div className="font-inter font-bold text-black text-lg">
            발송 전 메일 주소를 확인해주세요.
          </div>

          <div className="font-inter text-black opacity-50 text-xs mt-2">
            메일 타입
          </div>
          <div className="flex flex-row items-center">
            <div
              className={`font-inter text-xs ${
                isIndividual === "individual"
                  ? "text-black opacity-100"
                  : "text-black opacity-30"
              }`}
            >
              개인 발송
            </div>
            <Switch
              checked={checked2}
              onChange={toggleMail}
              inputProps={{ "aria-label": "controlled" }}
            />
            <div
              className={`font-inter text-xs ${
                isIndividual === "group"
                  ? "text-black opacity-100"
                  : "text-black opacity-30"
              }`}
            >
              전체 발송
            </div>
          </div>

          <div className="font-inter text-black opacity-50 text-xs mt-2">
            메일 발송시 언어 선택
          </div>
          <div className="flex flex-row items-center">
            <div
              className={`font-inter text-xs ${
                language === "ko"
                  ? "text-black opacity-100"
                  : "text-black opacity-30"
              }`}
            >
              KOR
            </div>
            <Switch
              checked={checked}
              onChange={handleChange}
              inputProps={{ "aria-label": "controlled" }}
            />
            <div
              className={`font-inter text-xs ${
                language === "en"
                  ? "text-black opacity-100"
                  : "text-black opacity-30"
              }`}
            >
              ENG
            </div>
          </div>

          <div className="font-inter text-black opacity-50 text-xs mt-2">
            자동완성 답변 타입
          </div>
          <FormControl component="fieldset">
            <RadioGroup
              row
              defaultValue="etc"
              name="radio-buttons-group"
              value={answerType}
              onChange={handleType}
            >
              <FormControlLabel
                value="information"
                control={<Radio />}
                label="information"
              />
              <FormControlLabel
                value="type2"
                control={<Radio />}
                label="type2"
              />
              <FormControlLabel
                value="type3"
                control={<Radio />}
                label="type3"
              />
              <FormControlLabel
                value="type4"
                control={<Radio />}
                label="type4"
              />
              <FormControlLabel
                value="type5"
                control={<Radio />}
                label="type5"
              />
              <FormControlLabel value="etc" control={<Radio />} label="etc" />
              <FormControlLabel
                value="promotion"
                control={<Radio />}
                label="promotion"
              />
              <FormControlLabel
                value="event"
                control={<Radio />}
                label="event"
              />
            </RadioGroup>
          </FormControl>

          {renderFormType(answerType)}
        </div>
      </div>

      <BasicModal
        onClose={() => {
          onChangeModal(false);
        }}
        visible={modal}
        stroke="#5F5A7A"
      >
        <div
          className="flex-col bg-white flex justify-center items-center"
          style={{ width: 444, height: 247 }}
        >
          <div className="flex flex-col gap-y-2">
            <div
              className="font-inter text-base text-center"
              style={{
                lineHeight: "19px",
              }}
            >
              받는 사람 이메일 주소 확인해주세요.
              <div className="font-mono text-lg font-bold mt-2 mb-2">
                메일 타입: {isIndividual === "individual" ? "개인" : "전체"}
                <br />
                받는 사람:{" "}
                {emailType === "all"
                  ? `${currentEmail} / ${
                      addList.length || sendList.length
                    } 그룹`
                  : `${addList.length || sendList.length}명`}
              </div>
              정말 보내시겠습니까?
            </div>
          </div>
          <div className="flex justify-center items-center w-full gap-x-3 relative top-8">
            <button
              type="button"
              className="outline-none"
              style={{ minWidth: 200 }}
              onClick={() => {
                if (emailType === "all") {
                  awaitSendMail(sendList.length);
                } else {
                  sendGroupEmail(sendList);
                }
              }}
            >
              <button
                type="button"
                className="text-lg font-mono w-40 py-3 px-6 bg-purple-200 hover:bg-purple-100"
              >
                <div className="font-mono text-lg text-white">보내기</div>
              </button>
            </button>
            <button
              type="button"
              className="outline-none"
              style={{ minWidth: 200 }}
              onClick={() => {
                onChangeModal(false);
              }}
            >
              <button
                type="button"
                className="text-lg font-mono w-40 py-3 px-6 bg-purple-200 hover:bg-purple-100"
              >
                <div className="font-mono text-lg text-white">취소</div>
              </button>
            </button>
          </div>
        </div>
      </BasicModal>
    </>
  );
};

export default EmailForm;
