import * as React from "react";
import { FC } from "react";
import PropTypes from "prop-types";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

import {
  Button,
  useUpdateMany,
  useNotify,
  useRefresh,
  useUnselectAll,
  CRUD_UPDATE_MANY,
  BulkActionProps,
  Identifier,
} from "react-admin";

const noSelection: Identifier[] = [];

const BulkAcceptButton: FC<BulkActionProps> = ({
  selectedIds = noSelection,
}) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const unselectAll = useUnselectAll("inquiry");

  const [approve, { loading }] = useUpdateMany(
    "inquiry",
    selectedIds,
    { status: "answered" },
    {
      action: CRUD_UPDATE_MANY,
      undoable: true,
      onSuccess: () => {
        notify(
          "resources.inquiry.notification.approved_success",
          "info",
          {},
          true
        );
        refresh();
        unselectAll();
      },
      onFailure: () => {
        notify("resources.inquiry.notification.approved_error", "warning");
      },
    }
  );

  return (
    <Button
      label="resources.inquiry.action.answered"
      onClick={approve}
      disabled={loading}
    >
      <CheckCircleIcon />
    </Button>
  );
};

BulkAcceptButton.propTypes = {
  selectedIds: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default BulkAcceptButton;
