import * as React from "react";
import { FC } from "react";
import { ReferenceField, ReferenceFieldProps } from "react-admin";

import FullNameField from "./FullNameField";

const UserReferenceField: FC<
  Omit<ReferenceFieldProps, "reference" | "children" | "source"> & {
    source?: string;
  }
> = (props) => {
  return (
    <ReferenceField source="parent_id" reference="user" {...props}>
      <FullNameField />
    </ReferenceField>
  );
};

UserReferenceField.defaultProps = {
  source: "parent_id",
  label: "resources.user.fields.name",
  addLabel: true,
};

export default UserReferenceField;
