import VoucherIcon from "@material-ui/icons/CardMembership";
import ChannelList from "./VoucherList";
import ChannelCreate from "./VoucherCreate";
import ChannelEdit from "./VoucherEdit";

const resource = {
  list: ChannelList,
  create: ChannelCreate,
  edit: ChannelEdit,
  icon: VoucherIcon,
};

export default resource;
