import * as React from "react";
import { FC } from "react";
import {
  useEditController,
  EditContextProvider,
  useTranslate,
  TextInput,
  SimpleForm,
  DateField,
  TextField,
  EmailField,
  EditProps,
} from "react-admin";
import { IconButton, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";

import UserReferenceField from "../users/UserReferenceField";
import ReviewEditToolbar from "./AsksEditToolbar";
import { Inquiry } from "../types";
import CommentField from "./CommentField";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 40,
  },
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "1em",
  },
  form: {
    [theme.breakpoints.up("xs")]: {
      width: 400,
    },
    [theme.breakpoints.down("xs")]: {
      width: "100vw",
      marginTop: -30,
    },
  },
  inlineField: {
    display: "inline-block",
    width: "50%",
  },
}));

interface Props extends EditProps {
  onCancel: () => void;
}

const AsksEdit: FC<Props> = ({ onCancel, ...props }) => {
  const classes = useStyles();
  const controllerProps = useEditController<Inquiry>(props);
  const translate = useTranslate();

  if (!controllerProps.record) {
    return null;
  }

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <Typography variant="h6">
          {translate("resources.asks.detail")}
        </Typography>
        <IconButton onClick={onCancel}>
          <CloseIcon />
        </IconButton>
      </div>
      <EditContextProvider value={controllerProps}>
        <SimpleForm
          className={classes.form}
          basePath={controllerProps.basePath}
          record={controllerProps.record}
          save={controllerProps.save}
          version={controllerProps.version}
          redirect="list"
          resource="asks"
          toolbar={<ReviewEditToolbar />}
        >
          <UserReferenceField formClassName={classes.inlineField} />
          <DateField
            source="date"
            showTime
            formClassName={classes.inlineField}
          />
          <EmailField source="email" />
          <TextField source="text" label="Subject" />
          <TextField
            source="text"
            label="Content"
            fullWidth
            className="block bg-gray-200 p-2 break-words"
          />
          {/* <TextField
            source="comment"
            fullWidth
            className="bg-gray-200 flex p-4"
          /> */}
          {/* <TextInput
            source="content"
            rowsMax={15}
            multiline
            fullWidth
            inputProps={{ readOnly: true }}
          /> */}
          <CommentField />
        </SimpleForm>
      </EditContextProvider>
    </div>
  );
};

export default AsksEdit;
