export const ENDPOINT =
  process.env.NODE_ENV === 'development'
    ? 'https://creativemind.ai/dev/back'
    : 'https://creativemind.ai/back';

const postData = (url = '', data = {}) =>
  fetch(url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    headers: {
      'Content-Type': 'application/json',
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  }).then((response) =>
    // console.log('result response: ', response);
    response.json(),
  ); // parses JSON response into native JavaScript objects

export const downloadFile = (data: any) =>
  new Promise((resolve) => {
    const options = {
      method: 'POST',
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
      url: `${ENDPOINT}/getVSTDownload`,
      maxContentLength: Infinity,
      maxBodyLength: Infinity,
      timeout: 60 * 30 * 1000,
    };

    fetch(`${ENDPOINT}/getVSTDownload`, options)
      .then((response: any) => {
        console.log('result response: ', response);

        return response.blob();
      })
      .then((blob: any) => {
        console.log('result blob: ', blob);

        const fileName =
          data.os === 'windows'
            ? 'MusiaPlugin_for_Windows.zip'
            : 'MusiaPlugin_for_MacOS.pkg';

        const fileUrl: string = window.URL.createObjectURL(
          new Blob([blob], { type: blob.type }),
        );
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();

        resolve(data);
      });
  });

export default postData;
