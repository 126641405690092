import { ReactElement, useEffect, useRef, useState } from "react";
import {
  Datagrid,
  DateField,
  DateInput,
  Filter,
  FilterProps,
  List,
  ListProps,
  NullableBooleanInput,
  NumberField,
  SearchInput,
  TextField,
  EmailField,
  UrlField,
  BooleanField,
  useTranslate,
} from "react-admin";
import { useMediaQuery, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircleOutline";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import {
  exportComponentAsJPEG,
  exportComponentAsPDF,
  exportComponentAsPNG,
} from "react-component-export-image";

// import SegmentsField from "./SegmentsField";
// import SegmentInput from "./SegmentInput";
import { ReactComponent as Svg } from "assets/voucherImg.svg";

const UserFilter = (props: Omit<FilterProps, "children">) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
    <DateInput source="visitAt" />
    <NullableBooleanInput source="has_newsletter" defaultValue />
    {/* <SegmentInput /> */}
    <NullableBooleanInput source="expired" />
  </Filter>
);

const useStyles = makeStyles((theme) => ({
  // nb_commands: { color: "purple" },
  // hiddenOnSmallScreens: {
  //   display: "table-cell",
  //   [theme.breakpoints.down("md")]: {
  //     display: "none",
  //   },
  // },

  headerCell: {
    fontWeight: "bold",
  },
}));

const VoucherList = (props: ListProps): ReactElement => {
  const classes = useStyles();
  const translate = useTranslate();
  const componentRef: any = useRef();

  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));

  // // 쿠폰 이미지 생성 자동화 만들어야 함
  // const codeList = [
  //   "PV2302l8OCeipPZs",
  //   "PV2302QWtJPosOl7",
  //   "PV2302Re5WqGfJmH",
  //   "PV2302CMVI9mQ3km",
  //   "PV2302PRnzDv6oY2",
  //   "PV2302yEhWimdJgK",
  //   "PV2302XO3OTDrUjO",
  //   "PV2302nIPyWvgA17",
  //   "PV2302fa0u00NjUv",
  //   "PV2302ldZuvyEtZY",
  //   "PV23028H6n6amgfr",
  //   "PV2302DcDx6KJZZv",
  //   "PV2302u44dB8hQqd",
  //   "PV2302Mp5xtgNocS",
  //   "PV2302C3Aro937Rl",
  //   "PV2302JQRNrZHU0y",
  //   "PV2302o7ZHHHgoEO",
  //   "PV2302cOI4pwGSet",
  //   "PV2302uZH2CIq40D",
  //   "PV2302EU7zXwDJ5s",
  //   "PV2302g5sfH64Hdc",
  //   "PV2302jK6PrtmfMT",
  //   "PV2302E10o47r1VR",
  //   "PV23023Ijonq4v84",
  //   "PV2302Zi38GfFxRr",
  //   "PV2302F7cmH4crWQ",
  //   "PV2302CVrUgIjo8N",
  //   "PV2302qNUOVEoqeU",
  //   "PV2302aj9dJHQqUr",
  //   "PV2302kT9jcVQS6V",
  // ];
  // const [codeIndex, setCodeIndex] = useState(0);

  // const AutoCreateVoucher = () => {
  //   return codeList.map((code, i) => {
  //     console.log("aaaaaaaaaaaaaa", i, code);
  //     setCodeIndex(i + 1);
  //     return (
  //       <div className="flex relative w-[707px]" ref={componentRef}>
  //         <Svg></Svg>
  //         <div className="w-[160px] absolute bottom-[50px] right-[223px] flex justify-center text-center">
  //           <div className="text-black font-inter text-base">
  //             {codeList[codeIndex]}
  //           </div>
  //         </div>
  //       </div>
  //     );
  //   });
  // };

  // useEffect(() => {
  //   if (codeIndex < codeList.length) {
  //     exportComponentAsPNG(componentRef, {
  //       fileName: `voucher_${codeIndex + 1}`,
  //       html2CanvasOptions: { backgroundColor: "transparent" },
  //     });

  //     setCodeIndex(codeIndex + 1);
  //   }
  // }, [codeIndex]);

  // return (
  //   <>
  //     <div className="flex relative w-[707px]" ref={componentRef}>
  //       <Svg></Svg>
  //       <div className="w-[160px] absolute bottom-[50px] right-[223px] flex justify-center text-center">
  //         <div className="text-black font-inter text-base">
  //           {codeList[codeIndex]}
  //         </div>
  //       </div>
  //     </div>
  //     <button
  //       onClick={() => {
  //         setCodeIndex(codeIndex + 1);
  //       }}
  //     >
  //       Export As PNG
  //     </button>
  //   </>
  // );

  return (
    <List
      {...props}
      // filters={isSmall ? <UserFilter /> : undefined}
      // sort={{ field: "visitAt", order: "ASC" }}
      // perPage={25}
      // aside={<Aside />}
    >
      <Datagrid classes={classes} optimized rowClick="edit">
        <NumberField source="id" />
        <TextField source="userId" />
        <EmailField source="email" />
        <TextField source="code" />
        <TextField source="type" />
        <TextField source="value" />
        <TextField source="source" />
        <DateField source="usedAt" />
        <DateField source="createdAt" />
        <BooleanField
          source="redeem"
          TrueIcon={CheckCircleIcon}
          FalseIcon={RemoveCircleIcon}
        />
        <BooleanField
          source="used"
          TrueIcon={CheckCircleIcon}
          FalseIcon={RemoveCircleIcon}
        />
        <BooleanField
          source="expired"
          TrueIcon={CheckCircleIcon}
          FalseIcon={RemoveCircleIcon}
        />
      </Datagrid>
    </List>
  );
};

export default VoucherList;
