import * as React from "react";
import { Fragment, FC } from "react";
import MuiToolbar from "@material-ui/core/Toolbar";
import { makeStyles } from "@material-ui/core/styles";

import { SaveButton, DeleteButton, ToolbarProps } from "react-admin";
import AcceptButton from "./AcceptButton";
import RejectButton from "./RejectButton";
import { Inquiry } from "../types";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "space-between",
  },
}));

const InquiryEditToolbar: FC<ToolbarProps> = ({
  basePath,
  handleSubmitWithRedirect,
  invalid,
  record,
  resource,
  saving,
}) => {
  const classes = useStyles();

  if (!record) return null;

  const renderView = (status: string) => {
    switch (status) {
      case "open":
        return (
          <Fragment>
            <AcceptButton record={record} />
            {/* <RejectButton record={record} /> */}
            <DeleteButton
              basePath={basePath}
              record={record}
              resource={resource}
            />
          </Fragment>
        );
      // case "answered":
      //   return (
      //     <Fragment>
      //       <AcceptButton record={record} />
      //       <DeleteButton
      //         basePath={basePath}
      //         record={record}
      //         resource={resource}
      //       />
      //     </Fragment>
      //   );
      // case "confirmed":
      case "answered":
        return (
          <Fragment>
            <RejectButton record={record} />
            <DeleteButton
              basePath={basePath}
              record={record}
              resource={resource}
            />
          </Fragment>
        );
      default:
        return (
          <Fragment>
            <AcceptButton record={record} />
            {/* <RejectButton record={record} /> */}
            <DeleteButton
              basePath={basePath}
              record={record}
              resource={resource}
            />
          </Fragment>
        );
    }
  };

  return (
    <MuiToolbar className={classes.root}>
      {renderView(record.status)}
      {/* {record.status === "inquired" ? (
        <Fragment>
          <AcceptButton record={record} />
          <DeleteButton
            basePath={basePath}
            record={record}
            resource={resource}
          />
        </Fragment>
      ) : (
        <Fragment>
       
          <AcceptButton record={record} />
          <RejectButton record={record} />
          <DeleteButton
            basePath={basePath}
            record={record}
            resource={resource}
          />
        </Fragment>
      )} */}
    </MuiToolbar>
  );
};

export default InquiryEditToolbar;
