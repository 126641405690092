import { fetchUtils } from "react-admin";
import { stringify } from "query-string";

export const apiUrl =
  process.env.NODE_ENV === "development"
    ? "https://creativemind.ai/dev/back"
    : "https://creativemind.ai/back";
const httpClient = fetchUtils.fetchJson;

const fetchData = {
  getList: (resource: any, params: any) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      resource,
      sort: [field, order],
      range: [(page - 1) * perPage, page * perPage - 1],
      filter: params.filter,
    };
    const url = `${apiUrl}/getList`;

    return httpClient(url, {
      method: "POST",
      body: JSON.stringify(query),
    }).then(({ headers, json }: any) => {
      return {
        data: json.pageData,
        total: json.total,
      };
    });
  },

  getOne: (resource: any, params: any) => {
    const query = {
      resource,
      filter: JSON.stringify({ id: params.id }),
    };
    const url = `${apiUrl}/getOne`;

    return httpClient(url, {
      method: "POST",
      body: JSON.stringify(query),
    }).then(({ json }) => ({ data: json }));

    // httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
    //   data: json,
    // })),
  },

  getMany: (resource: any, params: any) => {
    const query = {
      resource,
      filter: JSON.stringify({ id: params.ids }),
    };

    const url = `${apiUrl}/getMany`;

    return httpClient(url, {
      method: "POST",
      body: JSON.stringify(query),
    }).then(({ json }) => ({ data: json }));
  },

  getManyReference: (resource: any, params: any) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${apiUrl}/getManyReference`;

    return httpClient(url, {
      method: "POST",
      body: JSON.stringify(query),
    }).then(({ headers, json }: any) => ({
      data: json,
      total: parseInt(headers.get("content-range").split("/").pop(), 10),
    }));
  },

  update: (resource: any, params: any) => {
    const url = `${apiUrl}/updateOne`;

    return httpClient(url, {
      method: "POST",
      body: JSON.stringify({ resource, data: params.data, id: params.id }),
    }).then(({ json }) => ({ data: json }));
  },

  updateMany: (resource: any, params: any) => {
    const url = `${apiUrl}/updateMany`;

    return httpClient(url, {
      method: "POST",
      body: JSON.stringify({ resource, data: params.data, ids: params.ids }),
    }).then(({ json }) => ({ data: json }));
  },

  create: (resource: any, params: any) =>
    httpClient(`${apiUrl}/${resource}`, {
      method: "POST",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id },
    })),

  delete: (resource: any, params: any) => {
    // if (resource !== "youtube") {
    const url = `${apiUrl}/updateOne`;

    return httpClient(url, {
      method: "POST",
      body: JSON.stringify({
        resource,
        data: { status: "delete" },
        id: params.id,
      }),
    }).then(({ json }) => ({ data: json }));
    // } else {
    //   const query = {
    //     filter: JSON.stringify({ id: params.id }),
    //   };
    //   return httpClient(`${apiUrl}/${resource}`, {
    //     method: "DELETE",
    //     body: JSON.stringify(query),
    //   }).then(({ json }) => ({ data: json }));
    // }
  },

  deleteMany: (resource: any, params: any) => {
    // const query = {
    //   filter: JSON.stringify({ id: params.ids }),
    // };
    // return httpClient(`${apiUrl}/${resource}`, {
    //   method: "DELETE",
    //   body: JSON.stringify(query),
    // }).then(({ json }) => ({ data: json }));

    const url = `${apiUrl}/updateMany`;

    return httpClient(url, {
      method: "POST",
      body: JSON.stringify({
        resource,
        data: { status: "delete" },
        ids: params.ids,
      }),
    }).then(({ json }) => ({ data: json }));
  },
};

export default fetchData;
