import ShortUniqueId from "short-unique-id";
import * as React from "react";
import {
  Create,
  CreateProps,
  DateInput,
  SimpleForm,
  TextInput,
  useTranslate,
  PasswordInput,
  required,
  email,
  NumberInput,
  BooleanInput,
  SelectInput,
  useInput,
  TopToolbar,
  Button,
  Toolbar,
  SaveButton,
  useNotify,
} from "react-admin";
import { AnyObject } from "react-final-form";
import { Typography, Box } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Styles } from "@material-ui/styles/withStyles";

// import MakeVoucher from "utils/makeVoucher";

export const styles: Styles<Theme, any> = {
  first_name: { display: "inline-block" },
  last_name: { display: "inline-block", marginLeft: 32 },
  email: { width: 544 },
  address: { maxWidth: 544 },
  zipcode: { display: "inline-block" },
  city: { display: "inline-block", marginLeft: 32 },
  comment: {
    maxWidth: "20em",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  password: { display: "inline-block" },
  confirm_password: { display: "inline-block", marginLeft: 32 },
};

const useStyles = makeStyles(styles);

export const validatePasswords = ({
  password,
  confirm_password,
}: AnyObject) => {
  const errors = {} as any;

  if (password && confirm_password && password !== confirm_password) {
    errors.confirm_password = ["resources.voucher.errors.password_mismatch"];
  }

  return errors;
};

const VoucherCreate = (props: CreateProps) => {
  const classes = useStyles(props);
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;

  const makeUID = new ShortUniqueId({ length: 10 });
  const voucherCode = `${makeUID()}`;
  // const voucherCode = `${year.toString().slice(-2)}${month}${makeUID()}`;
  const translate = useTranslate();
  const [code, setCode] = React.useState("");
  const [codeYYMM, setCodeYYMM] = React.useState("");

  React.useEffect(() => {
    if (code.length === 2 && codeYYMM.length === 4) {
      setCode(`${code.toUpperCase()}${codeYYMM}${voucherCode}`);
    }
  }, [code, codeYYMM]);

  const handleCode = (e: any) => {
    if (e.target === undefined) {
      setCode("");
    } else {
      const { value } = e.target;
      setCode(value);
    }
  };

  const handleCode2 = (e: any) => {
    if (e.target === undefined) {
      setCodeYYMM("");
    } else {
      const { value } = e.target;
      setCodeYYMM(value);
    }
  };

  // MakeVoucher();

  // 나중에 여러장 한번에 생성할 수 있도록 버튼 구현
  const PostCreateToolbar = () => {
    const notify = useNotify();
    // const { reset }: any = useFormContext();

    return (
      <Toolbar>
        <SaveButton
          type="button"
          label="Create"
          variant="text"
          onSuccess={() => {
            // reset();
            window.scrollTo(0, 0);
            notify("ra.notification.created", {
              type: "info",
              messageArgs: { smart_count: 1 },
            });
          }}
        />
      </Toolbar>
    );
  };

  return (
    <Create {...props}>
      <SimpleForm
        // Here for the GQL provider
        initialValues={{
          createdAt: date,
          redeem: false,
          used: false,
          expired: false,
          code: code,
        }}
        validate={validatePasswords}
        // toolbar={<PostCreateToolbar />}
      >
        <SectionTitle label="resources.voucher.fieldGroups.identity" />
        <TextInput
          autoFocus
          source="userId"
          formClassName={classes.first_name}
          disabled
        />
        <TextInput source="email" formClassName={classes.last_name} disabled />
        <br />
        <SelectInput
          autoFocus
          source="type"
          validate={requiredValidate}
          formClassName={classes.first_name}
          choices={[
            { id: "months", name: "months" },
            { id: "years", name: "years" },
          ]}
        />
        <NumberInput
          source="value"
          formClassName={classes.last_name}
          validate={requiredValidate}
          min="1"
        />
        <TextInput
          source="source"
          fullWidth
          // formClassName={classes.email}
          validate={requiredValidate}
          resettable
        />

        <div className="flex flrx-row justify-center items-center">
          <label>Event: </label>
          <input
            onChange={handleCode}
            className="bg-gray-100 rounded-lg py-2 px-4 ml-2"
            maxLength={2}
          />
        </div>
        <div className="flex flrx-row justify-center items-center mt-2">
          <label>Date: </label>
          <input
            onChange={handleCode2}
            className="bg-gray-100 rounded-lg py-2 px-4 ml-2"
            maxLength={4}
          />
        </div>
        <TextInput
          source="code"
          // validate={requiredValidate}
          fullWidth
          // resettable
          disabled
          formClassName={classes.first_name}
          // disabled={code.length >= 16}
        />

        <br />
        <DateInput
          source="usedAt"
          disabled
          formClassName={classes.first_name}
        />
        <DateInput
          source="createdAt"
          disabled
          formClassName={classes.last_name}
        />
        <br />
        <BooleanInput
          source="redeem"
          formClassName={classes.first_name}
          disabled
        />
        <BooleanInput
          source="used"
          formClassName={classes.last_name}
          disabled
        />
        <BooleanInput
          source="expired"
          formClassName={classes.last_name}
          disabled
        />
      </SimpleForm>
    </Create>
  );
};

const requiredValidate = [required()];

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label)}
    </Typography>
  );
};

const Separator = () => <Box pt="1em" />;

export default VoucherCreate;
