import React, { FC, useState, useEffect, useRef } from "react";
import { Users } from "../types";
import {
  FieldProps,
  useUpdate,
  useNotify,
  useRedirect,
  useRefresh,
  BooleanInput,
} from "react-admin";

import styled from "styled-components";
import { apiUrl } from "dataProvider";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Switch,
} from "@material-ui/core";

const InputBox = styled.input`
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  height: 100%;
  letter-spacing: 0.02rem;
  color: black;
  width: 100%;

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #ffffff inset;
    -webkit-text-fill-color: #000000;
  }

  &::placeholder {
    color: #b1afb8;
  }
`;

const TextArea = styled.textarea`
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.02rem;
  color: black;
  width: 100%;
  height: 16.8rem;
  border: none;
  margin: 16px 0;
  background: transparent;

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #ffffff inset;
  }
  &:focus {
    outline: none !important;
  }

  &::placeholder {
    color: #b1afb8;
  }
`;

const ContentCount = styled.div`
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 100%;
  text-align: right;
  color: #504e55;
  margin-bottom: 8px;
`;

interface Props extends FieldProps<Users> {
  className?: string;
  size?: string;
}

const CommentField: FC<Props> = ({ record }: any) => {
  const notify = useNotify();
  const refresh = useRefresh();

  const [from, setFrom] = useState("");
  const [subject, setSubject] = useState("");
  const [content, setContent] = useState("");
  const [comment, setConmment] = useState({ answer: "", date: "" });
  const [selectedFile, setSelectedFile]: any = useState(null);
  const [checked, setChecked] = useState(false);
  const [language, setLanguage] = useState("ko");
  const [answerType, setAnswerType] = useState("etc");
  const [autoAnswer, setAutoAnswer]: any = useState({
    information: {
      en: `Please let us know the following:
    - Version of MUSIA Plugin
    - OS (Ex. Windows 10)
    - DAW (Ex. Ableton Live 11)\n
    Also, if you can provide detailed situation, it will be more helpful to solve your problem.\n
    If you contact us again with the above information, we will help you as soon as possible!
    `,
      ko: `다음의 사항들을 알려주세요:
    - MUSIA Plugin의 버전
    - OS (Ex. Windows 10)
    - DAW (Ex. Ableton Live 11)\n
    또한 자세한 상황을 알려주시면, 문제 해결에 도움이 됩니다.\n
    위의 정보들로 다시 한번 문의 주시면 최대한 빠르게 도움을 드리도록 하겠습니다.`,
    },
    type2: "",
    type3: "",
    type4: "",
    type5: "",
  });

  const handleFileChange = (event: any) => {
    setSelectedFile(event.target.files);
  };

  const handleFileUpload = async (content: any) => {
    const formData = new FormData();

    if (selectedFile) {
      for (let i = 0; i < selectedFile.length; i++) {
        formData.append(`sendFile-${i}`, selectedFile[i], selectedFile[i].name);
      }
    }

    formData.append("content", JSON.stringify(content));

    const res: any = await fetch(`${apiUrl}/answerMail`, {
      method: "POST",
      // headers: { "Content-Type": "multipart/form-data" },
      body: formData,
    });

    console.log("file attch: ", res);
  };

  useEffect(() => {
    setFrom(record.email);
    setSubject(record.subject || record.text);
    setContent("");

    const data = JSON.parse(record.comment);
    setConmment(data);
  }, [record]);

  const onChangeContent = (e: any) => {
    const { value } = e.target;
    setContent(value);
  };

  const [approve, { loading }] = useUpdate(
    "asks",
    record.id,
    {
      comment: { answer: content, date: new Date() },
      status: "answered",
    },
    record,
    {
      undoable: false,
      onSuccess: async (data) => {
        notify(
          "resources.asks.notification.approved_success",
          "info",
          {},
          true
        );
        refresh();
        handleFileUpload(data);
        // const res: any = await fetch(`${apiUrl}/answerMail`, {
        //   method: "POST",
        //   headers: { "Content-Type": "application/json" },
        //   body: JSON.stringify(data),
        // });
      },
      onFailure: () => {
        notify("resources.asks.notification.approved_error", "warning");
      },
    }
  );

  const postComment = () => {
    if (!comment) {
      approve();
    }
  };

  const blockBtn = () => {
    if (content.length === 0 || loading || comment) {
      return true;
    }

    return false;
  };

  const handleType = (event: any, value: string) => {
    setAnswerType(value);
  };

  const handleChange = (event: any, checked: boolean) => {
    setChecked(checked);
    if (checked) {
      setLanguage("en");
    } else {
      setLanguage("ko");
    }
  };

  useEffect(() => {
    switch (answerType) {
      case "information":
        setContent(autoAnswer.information[language]);
        break;
      default:
        setContent("");
        break;
    }
  }, [language, answerType]);

  useEffect(() => {
    console.log("select language: ", language);
    console.log("select auto type: ", answerType);
  }, [language, answerType]);

  const renderComment = () => {
    if (comment !== null) {
      const { answer, date } = comment;
      const time = new Date(date).toLocaleString();
      //   return (
      //     <div className="flex flex-col">
      //       {comment.map((item, index) => {
      //         const { answer, date } = item;
      //         const time = new Date(date).toLocaleString();
      //         return (
      //           <div
      //             className={`flex flex-row p-2 ${
      //               index % 2 === 0 ? "bg-gray-100" : "bg-gray-200"
      //             }`}
      //           >
      //             <div className="flex flex-col">
      //               <div className="text-xs flex flex-row">
      //                 {index + 1}. 시간: {time}
      //               </div>
      //               <div className="px-2 text-sm">내용: {answer}</div>
      //             </div>
      //           </div>
      //         );
      //       })}
      //     </div>
      // );

      return (
        <div className="flex flex-col">
          <div className="text-xs flex flex-row">작성일: {time}</div>
          <div className="text-sm">
            {answer.split("\n").map((list: string, i: number) => (
              <span key={`${i}-${list}`}>
                {list}
                <br />
              </span>
            ))}
          </div>
        </div>
      );
    }
  };

  return (
    <div className="sendInquire w-full pt-8">
      <div className="font-inter text-black opacity-50 text-xs mb-2">
        이전 답변
      </div>
      <div className="flex flex-row p-2 bg-gray-200">{renderComment()}</div>
      <div className="flex flex-col mt-8">
        <div className="font-inter font-bold text-black text-lg">
          답변 등록 시 입력된 메일 주소로 답변이 발송됩니다.
        </div>

        <div className="font-inter text-black opacity-50 text-xs mt-2">
          메일 발송시 언어 선택
        </div>
        <div className="flex flex-row items-center">
          <div
            className={`font-inter text-xs ${
              language === "ko"
                ? "text-black opacity-100"
                : "text-black opacity-30"
            }`}
          >
            KOR
          </div>
          <Switch
            checked={checked}
            onChange={handleChange}
            inputProps={{ "aria-label": "controlled" }}
          />
          <div
            className={`font-inter text-xs ${
              language === "en"
                ? "text-black opacity-100"
                : "text-black opacity-30"
            }`}
          >
            ENG
          </div>
        </div>

        <div className="font-inter text-black opacity-50 text-xs mt-2">
          자동완성 답변 타입
        </div>
        <FormControl component="fieldset">
          <RadioGroup
            row
            defaultValue="etc"
            name="radio-buttons-group"
            value={answerType}
            onChange={handleType}
          >
            <FormControlLabel
              value="information"
              control={<Radio />}
              label="information"
            />
            <FormControlLabel value="type2" control={<Radio />} label="type2" />
            <FormControlLabel value="type3" control={<Radio />} label="type3" />
            <FormControlLabel value="type4" control={<Radio />} label="type4" />
            <FormControlLabel value="type5" control={<Radio />} label="type5" />
            <FormControlLabel value="etc" control={<Radio />} label="etc" />
          </RadioGroup>
        </FormControl>
        <div
          className={`border border-solid border-black mt-4 table-fixed font-inter text-black`}
        >
          <div>
            <div
              className={`p-4 flex h-14 border-b border-black
              `}
            >
              <div className="font-bold ">답변 등록</div>
            </div>
            <div className="min-w-max px-4 flex">
              <div className="border-b border-black flex flex-row w-full h-14">
                <div className="font-bold flex justify-center items-center">
                  이메일:
                </div>
                <div className="outline-none flex justify-center items-center pl-2">
                  {from}
                </div>
              </div>
            </div>
            <div className="px-4 flex whitespace-nowrap overflow-ellipsis overflow-hidden">
              <div className="border-b border-black flex flex-row w-full h-14">
                <div className="font-bold flex justify-center items-center">
                  문의사항:
                </div>
                <div className="outline-none flex justify-center items-center px-2">
                  {subject}
                </div>
              </div>
            </div>
            <div className="px-4 flex border-b border-black">
              <div className="font-inter text-sm flex flex-col w-full">
                <TextArea
                  placeholder="답변 내용..."
                  value={content}
                  onChange={onChangeContent}
                  className="pt-1"
                  required
                  style={{ lineHeight: "20px" }}
                />
                <ContentCount>{content.length}/1000</ContentCount>
              </div>
            </div>
            <div className="p-4 flex">
              <input type="file" multiple onChange={handleFileChange} />
              <button
                className="outline-none"
                type="button"
                onClick={postComment}
                disabled={blockBtn()}
              >
                <div
                  className={`
                      text-lg font-mono py-3 px-6
                      ${
                        content.length > 0 && !blockBtn()
                          ? "bg-purple-200"
                          : "bg-purple-100"
                      }
                      ${content.length > 0 ? "hover:bg-purple-100" : ""}`}
                >
                  <div className="font-mono text-lg text-white">SEND</div>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommentField;
