// in src/comments.js
import * as React from "react";
import { FC } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import { makeStyles } from "@material-ui/core/styles";
import {
  DateField,
  EditButton,
  useTranslate,
  NumberField,
  Identifier,
} from "react-admin";

import AvatarField from "./AvatarField";
// import ColoredNumberField from './ColoredNumberField';
// import SegmentsField from './SegmentsField';
import { Users } from "../types";

const useStyles = makeStyles((theme) => ({
  root: { margin: "1em" },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    margin: "0.5rem 0",
  },
  cardTitleContent: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: 10,
  },
  cardContent: {
    ...theme.typography.body1,
    display: "flex",
    flexDirection: "column",
  },
}));

interface Props {
  ids?: Identifier[];
  data?: { [key: string]: Users };
  basePath?: string;
}

const MobileGrid: FC<Props> = ({ ids, data, basePath }) => {
  const translate = useTranslate();
  const classes = useStyles();

  if (!ids || !data) {
    return null;
  }

  return (
    <div className={classes.root}>
      {ids.map((id) => (
        <Card key={id} className={classes.card}>
          <CardHeader
            title={
              <div className={classes.cardTitleContent}>
                <h2>{`${data[id].displayName} ${data[id].email}`}</h2>

                <EditButton
                  resource="user"
                  basePath={basePath}
                  record={data[id]}
                />
              </div>
            }
            avatar={<AvatarField record={data[id]} size="45" />}
          />
          <CardContent className={classes.cardContent}>
            <div>
              {translate("resources.user.fields.last_seen_date")}
              &nbsp;
              <DateField record={data[id]} source="last_seen_date" />
            </div>
            <div>
              {translate("resources.commands.name", data[id].inquiry || 1)}
              &nbsp;:&nbsp;
              <NumberField
                record={data[id]}
                source="inquiry"
                label="resources.customers.fields.commands"
              />
            </div>
            {/* <div>
              {translate("resources.customers.fields.total_spent")}
              &nbsp; :{" "}
              <ColoredNumberField
                record={data[id]}
                source="total_spent"
                options={{ style: "currency", currency: "USD" }}
              />
            </div> */}
          </CardContent>
          {/* {data[id].groups && data[id].groups.length > 0 && (
                        <CardContent className={classes.cardContent}>
                            <SegmentsField record={data[id]} />
                        </CardContent>
                    )} */}
        </Card>
      ))}
    </div>
  );
};

MobileGrid.defaultProps = {
  data: {},
  ids: [],
};

export default MobileGrid;
