import * as React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import LabelIcon from "@material-ui/icons/Label";
import { makeStyles } from "@material-ui/core/styles";
import {
  useTranslate,
  DashboardMenuItem,
  MenuItemLink,
  MenuProps,
  ReduxState,
} from "react-admin";

import users from "../users";
import inquiry from "../inquiry";
import email from "../email";
import youtube from "../youtube";
import voucher from "../voucher";
import SubMenu from "./SubMenu";
import { AppState } from "../types";
import classnames from "classnames";

type MenuName = "menuCatalog" | "menuSales" | "menuCustomers";

const Menu = ({ dense = false }: MenuProps) => {
  const [state, setState] = useState({
    menuCatalog: true,
    menuSales: true,
    menuCustomers: true,
  });
  const open = useSelector((state: ReduxState) => state.admin.ui.sidebarOpen);
  const translate = useTranslate();
  useSelector((state: AppState) => state.theme); // force rerender on theme change
  const classes = useStyles();

  const handleToggle = (menu: MenuName) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  return (
    <div
      className={classnames(classes.root, {
        [classes.open]: open,
        [classes.closed]: !open,
      })}
    >
      <DashboardMenuItem />
      {/* <SubMenu
        handleToggle={() => handleToggle("menuUsers")}
        isOpen={state.menuUsers}
        name="pos.menu.users"
        icon={<users.icon />}
        dense={dense}
      >
        <MenuItemLink
          to={`/customers`}
          primaryText={translate(`resources.customers.name`, {
            smart_count: 2,
          })}
          leftIcon={<users.icon />}
          dense={dense}
        />
        <MenuItemLink
          to={`/segments`}
          primaryText={translate(`resources.segments.name`, {
            smart_count: 2,
          })}
          leftIcon={<LabelIcon />}
          dense={dense}
        />
      </SubMenu> */}
      <MenuItemLink
        to={`/user`}
        primaryText={translate(`resources.user.name`, {
          smart_count: 2,
        })}
        leftIcon={<users.icon />}
        dense={dense}
      />
      <SubMenu
        handleToggle={() => handleToggle("menuSales")}
        isOpen={state.menuSales}
        name="pos.menu.inquiry"
        icon={<inquiry.icon />}
        dense={dense}
      >
        <MenuItemLink
          to={{
            pathname: "/inquiry",
            state: { _scrollToTop: true },
          }}
          primaryText={translate(`resources.inquiry.name`, {
            smart_count: 2,
          })}
          leftIcon={<inquiry.icon />}
          dense={dense}
        />
        <MenuItemLink
          to={{
            pathname: "/asks",
            state: { _scrollToTop: true },
          }}
          primaryText={translate(`resources.asks.name`, {
            smart_count: 2,
          })}
          leftIcon={<inquiry.icon />}
          dense={dense}
        />
      </SubMenu>

      <MenuItemLink
        to={`/email`}
        primaryText={translate(`resources.email.name`, {
          smart_count: 2,
        })}
        leftIcon={<email.icon />}
        dense={dense}
      />
      <MenuItemLink
        to={`/youtube`}
        primaryText={translate(`resources.youtube.name`, {
          smart_count: 2,
        })}
        leftIcon={<youtube.icon />}
        dense={dense}
      />
      <MenuItemLink
        to={`/voucher`}
        primaryText={translate(`resources.voucher.name`, {
          smart_count: 2,
        })}
        leftIcon={<voucher.icon />}
        dense={dense}
      />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  open: {
    width: 200,
  },
  closed: {
    width: 55,
  },
}));

export default Menu;
