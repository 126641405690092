import * as React from "react";
import { FC } from "react";
import { Card as MuiCard, CardContent } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import MailIcon from "@material-ui/icons/MailOutline";
import RemoveCircleOutlineOutlinedIcon from "@material-ui/icons/RemoveCircleOutlineOutlined";
import LocalOfferIcon from "@material-ui/icons/LocalOfferOutlined";
import { FilterList, FilterListItem, FilterLiveSearch } from "react-admin";
import {
  endOfYesterday,
  startOfWeek,
  endOfWeek,
  subWeeks,
  startOfMonth,
  subMonths,
} from "date-fns";

// import segments from '../segments/data';

const Card = withStyles((theme) => ({
  root: {
    [theme.breakpoints.up("sm")]: {
      order: -1,
      width: "15em",
      marginRight: "1em",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}))(MuiCard);

const Aside: FC = () => (
  <Card>
    <CardContent>
      <FilterLiveSearch />

      <FilterList
        label="resources.user.filters.last_visited"
        icon={<AccessTimeIcon />}
      >
        <FilterListItem
          label="resources.user.filters.today"
          value={{
            label: "today",
            start_date: endOfYesterday().toISOString(),
            end_date: undefined,
          }}
        />
        <FilterListItem
          label="resources.user.filters.this_week"
          value={{
            label: "this_week",
            start_date: startOfWeek(new Date()).toISOString(),
            end_date: endOfWeek(new Date()).toISOString(),
          }}
        />
        <FilterListItem
          label="resources.user.filters.last_week"
          value={{
            label: "last_week",
            start_date: subWeeks(startOfWeek(new Date()), 1).toISOString(),
            end_date: startOfWeek(new Date()).toISOString(),
          }}
        />
        <FilterListItem
          label="resources.user.filters.this_month"
          value={{
            label: "this_month",
            start_date: startOfMonth(new Date()).toISOString(),
            end_date: undefined,
          }}
        />
        <FilterListItem
          label="resources.user.filters.last_month"
          value={{
            label: "last_month",
            start_date: subMonths(startOfMonth(new Date()), 1).toISOString(),
            end_date: startOfMonth(new Date()).toISOString(),
          }}
        />
      </FilterList>

      <FilterList
        label="resources.user.filters.has_newsletter"
        icon={<MailIcon />}
      >
        <FilterListItem
          label="ra.boolean.true"
          value={{ has_newsletter: true }}
        />
        <FilterListItem
          label="ra.boolean.false"
          value={{ has_newsletter: false }}
        />
      </FilterList>

      <FilterList
        label="resources.user.filters.expire"
        icon={<RemoveCircleOutlineOutlinedIcon />}
      >
        <FilterListItem label="ra.boolean.true" value={{ expired: true }} />
        <FilterListItem label="ra.boolean.false" value={{ expired: false }} />
      </FilterList>

      {/* <FilterList
                label="resources.user.filters.group"
                icon={<LocalOfferIcon />}
            >
                {segments.map(segment => (
                    <FilterListItem
                        label={segment.name}
                        key={segment.id}
                        value={{ groups: segment.id }}
                    />
                ))}
            </FilterList> */}
    </CardContent>
  </Card>
);

export default Aside;
